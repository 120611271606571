import InviteContentListItem from "./InviteContentListItem";
import "../index.css";
import Empty from "../../../../components/Empty";

function InviteList(props: any) {
  const { inviteList } = props;

  return (
    <div className="main-content-bd">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="inv-content">
              {inviteList?.map((item: any, idx: number) => {
                return <InviteContentListItem {...item} key={idx} />;
              })}
              {inviteList?.length <= 0 && <Empty />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InviteList;
