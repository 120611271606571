import { Tooltip } from "antd";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router";

const invitedUrl = "/user/eligibility";

function PageHeader(props: any) {
  const { point } = props;
  const location = useLocation();
  const [showInfo, _] = useState(!location.pathname.includes(invitedUrl));
  const navigate = useNavigate();

  const onActive = () => {
    navigate(`${invitedUrl}/0`);
  };

  // const isInvited = window.location.href.indexOf('invited-info') > -1;

  return (
    <div className="invitation-code-hd">
      <div className="inv-hd-l">
        {showInfo ? "Invitation Code" : "Game eligibility"}
      </div>
      <div className="inv-hd-r">
        <div className="inv-info">
          {showInfo && (
            <div className="inv-tit" style={{ display: "flex" }}>
              <Tooltip
                placement="bottom"
                title="Invitation points are based on the number, duration and quality of Spaceship NFT. The higher the point, the more invitation codes you get."
              >
                <img
                  style={{ width: "25px", height: "25px", marginRight: "5px" }}
                  src="https://assets.outer.gg/frontend/img/invitation/dd_tips.png"
                  alt=""
                />
              </Tooltip>
              <span>
                You have <span className="inv-points">{point || 0}</span>{" "}
                invitation points
              </span>
            </div>
          )}

          {/* <div className="inv-desc">( 150 invitation points = 1 invitation code )</div> */}
        </div>
        {showInfo && (
          <div className="inv-bloom">
            <button type="button" className="o-btn-primary" onClick={onActive}>
              ACTIVE
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default PageHeader;
