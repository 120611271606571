import ContentBlock from "./components/ContentBlock";
import WalletItem from "./components/WalletItem";
import { getWalletCfg, walletDic } from "./constance";
import styles from "./index.module.css";

function GetWallet() {
  return (
    <div className={styles.getWallet}>
      {Object.keys(walletDic).map((key: any, idx: number) => {
        const item = walletDic[key];
        return <WalletItem key={idx} type={item.type} />;
      })}
      <div className={styles.getWalletContent}>
        <ContentBlock {...getWalletCfg} />
      </div>
    </div>
  );
}

export default GetWallet;
