import { isPrd as checkIsPrd, isPrd } from "../../utils";
import addressConfig from "./address";
import prdCfg from "./standardNet";
import testCfg from "./testNet";
import { http } from "wagmi";

/**
 * 获取当前支持链的wagmi配置
 * @returns
 */
export function getContractCfg() {
  const ispPrd = checkIsPrd();
  const transports: any = {};

  const chains = Object.values(ispPrd ? prdCfg : testCfg).map((item: any) => {
    const rpcUrl = Array.isArray(item.rpcUrls)
      ? item.rpcUrls[0]
      : item?.rpcUrls?.default?.http;
    transports[item.id] = http(rpcUrl);
    return item;
  });

  return {
    chains,
    transports,
  };
}

/**
 * 获取各条链上面合约的地址
 */
export function getContractAddressCfg() {
  return addressConfig;
}

/**
 * ok钱包某些连不支持签名，需要切到支持的练
 */

export function getBeforeSignChain() {
  return isPrd() ? 0x1 : 0xaa36a7;
}
