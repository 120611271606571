import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ModalContainer from "../Container";
import styles from "./index.module.css";
import Header from "./Header";
import ModalBody from "./ModalBody";
import ModalBottom from "./ModalBottom";
import { TypeSwitchNetwrodModal } from "./type";

const Content = (props: TypeSwitchNetwrodModal) => {
  const { switchFn, disconnectFn } = props;
  const modal = useModal();

  const onCloseFn = () => {
    modal.remove();
  };

  return (
    <div
      className={`modal fade ${modal.visible ? "show" : ""}`}
      id="chainModal"
      data-bs-backdrop="static"
      tabIndex={-1}
      aria-labelledby=""
      aria-modal="true"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
        style={{ maxWidth: "420px" }}
      >
        <div className={styles["chain-modal-content"]}>
          <Header onClose={onCloseFn} />
          <ModalBody onClose={onCloseFn} switchFn={switchFn} />
          <ModalBottom onClose={onCloseFn} disconnectFn={disconnectFn} />
        </div>
      </div>
    </div>
  );
};

export default NiceModal.create(Content);
