import styels from "./index.module.css";

function BackBtn(props: { onBack: () => void }) {
  const { onBack } = props;
  return (
    <div className={styels.backBtn} onClick={onBack}>
      <svg
        fill="none"
        height="17"
        viewBox="0 0 11 17"
        width="11"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Back</title>
        <path
          d="M0.99707 8.6543C0.99707 9.08496 1.15527 9.44531 1.51562 9.79688L8.16016 16.3096C8.43262 16.5732 8.74902 16.7051 9.13574 16.7051C9.90918 16.7051 10.5508 16.0811 10.5508 15.3076C10.5508 14.9121 10.3838 14.5605 10.0938 14.2705L4.30176 8.64551L10.0938 3.0293C10.3838 2.74805 10.5508 2.3877 10.5508 2.00098C10.5508 1.23633 9.90918 0.603516 9.13574 0.603516C8.74902 0.603516 8.43262 0.735352 8.16016 0.999023L1.51562 7.51172C1.15527 7.85449 1.00586 8.21484 0.99707 8.6543Z"
          fill="currentColor"
        ></path>
      </svg>
    </div>
  );
}

export default BackBtn;
