import { configureStore } from "@reduxjs/toolkit";
import counterSlice from "./features/testSlice";
import playerSlice from "./features/playerSlice";

// configureStore创建一个redux数据
const store = configureStore({
  // 合并多个Slice
  reducer: {
    counter: counterSlice,
    player: playerSlice
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
