import {
  useAccount,
  useSwitchChain,
  useReconnect,
  useSignMessage,
} from "wagmi";
import { getDefaultChainId, isInOKWalletApp, isPrd } from "../utils";
import { getBeforeSignChain } from "@/config/contract";
import { useCallback } from "react";

/**
 * 钱包签名钩子,需要在useConnectThen后调用，以确保钱包已经连接
 * @param msg 签名信息
 * @param fn 会掉函数
 * @param chainId 签名后回到的李娜
 * @returns
 */
function useWalletSign() {
  const { switchChainAsync } = useSwitchChain();
  const { signMessage } = useSignMessage();

  const signFn = useCallback(
    async (msg: string, fn: (msg: string) => void, chainId?: number) => {
      const message = msg + new Date().getTime();

      // 是否在 oxk 钱包 app里面
      const isInOkxApp = isInOKWalletApp();
      if (isInOkxApp) {
        // ok钱包某些连不支持签名，需要切到支持的练
        await switchChainAsync({ chainId: getBeforeSignChain() });
      }

      signMessage(
        { message },
        {
          onSuccess: async (signature: string) => {
            if (isInOkxApp) {
              // 切到推荐的链
              const defaultChainId = getDefaultChainId();
              await switchChainAsync({ chainId: chainId || defaultChainId });
            }

            fn?.(signature);
          },
        }
      );
    },
    []
  );

  return signFn;
}

export default useWalletSign;
