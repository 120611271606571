import { setLoginInfo } from "@/utils";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDisconnect } from "wagmi";

function useLoginOut() {
  const { disconnect } = useDisconnect();
  const navigate = useNavigate();

  const disconnectFn = useCallback(() => {
    setLoginInfo(null, true);
    disconnect();
    navigate("/login");
  }, []);

  return disconnectFn;
}

export default useLoginOut;
