export enum EnumWalletItem {
  okx = "okx",
  metamask = "metamask",
}

export const walletDic: any = {
  [EnumWalletItem.okx]: {
    img: "https://assets.outer.gg/frontend/img/common/okxIcon.png",
    desc: "OKX Wallet",
    type: EnumWalletItem.okx,
    url: "https://www.okx.com/web3",
  },
  [EnumWalletItem.metamask]: {
    img: "https://assets.outer.gg/frontend/img/common/metamask.svg",
    desc: "MetaMask",
    type: EnumWalletItem.metamask,
    url: "http://metamask.io/",
  },
};

export const mainContentCfg: any = {
  title: "common.what_is_a_wallet", //'//"What is a Wallet?",
  content: "common.what_is_a_wallet_content",
  // "A wallet is used to send, receive, store, and display digital assets. It's also a new way to log in, without needing to create new accounts and passwords on every website.",
};

export const getWalletCfg: any = {
  title: "common.not_what_youre_looking_for", //"Not what you're looking for?",
  content: "common.not_what_youre_looking_for_content",
  // "Select a wallet on the main screen to get started with a different wallet provider.",
};
