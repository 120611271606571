import { createSlice } from "@reduxjs/toolkit";
import { DataType } from "../../dataType";
import { TypeInitialState } from "../types";
import { setLoginInfo } from "@/utils";

const initialState: TypeInitialState = {
  user: {
    address: "",
    clientPassword: "",
    email: "",
    username: "",
    taskScore: 0,
    hasBindEmail: false,
  },
  passPlusInfo: {},
  nftItemData: {} as DataType,
  outToken: 0,
};

// 创建一个 Slice
export const playerSlice = createSlice({
  name: "player",
  initialState,
  // 定义 reducers 并生成关联的操作
  reducers: {
    setUser(state, action) {
      setLoginInfo(action.payload);
      state.user = action.payload;
    },
    setPassPlusInfo: (state, action) => {
      state.passPlusInfo = action.payload;
    },
    setNFTItemData(state, action) {
      state.nftItemData = action.payload;
    },
    setOutToken: (state, action) => {
      state.outToken = action.payload;
    },
  },
});
// 导出加减的方法
export const { setUser, setPassPlusInfo, setNFTItemData, setOutToken } =
  playerSlice.actions;

// 默认导出
export default playerSlice.reducer;
