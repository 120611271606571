import { Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import InviteCode from "../pages/UserCenter/inviteCode/mainPage";
import MiniInvite from "../pages/MiniInvite";
import SpanLoading from "@/components/SpanLoading";
import PageInvite from "@/pages/Invite";
import LoadingScreen from "@/components/LoadingScreen";
// import BindUsername from "@/pages/BindUsername";
// import Registercomfirm from "@/pages/RegisterComfirm";

function LazyCom(props: any) {
  const { com } = props;
  const Com = com;
  return (
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <LoadingScreen />
        </div>
      }
    >
      <Com />
    </Suspense>
  );
}

const MainLayout = lazy(() => import("../layout/MainLayout"));
const AccountLayout = lazy(() => import("../layout/AccountLayout"));
const TestWagmi = lazy(() => import("../pages/Test/TestWagmi"));
const Test = lazy(() => import("../pages/Test/Test"));
const Home = lazy(() => import("../pages/Home/index"));
const RefferralProgram = lazy(
  () => import("../pages/UserCenter/ReferralProgram/index")
);
const Login = lazy(() => import("../pages/Login/index"));
const Register = lazy(() => import("../pages/Register/index"));
const Dashboard = lazy(() => import("../pages/UserCenter/Dashboard"));
const Members = lazy(() => import("../pages/UserCenter/Members/index"));
const SignInReward = lazy(() => import("../pages/UserCenter/SignInReward"));
const ClaimTokens = lazy(() => import("../pages/UserCenter/ClaimTokens"));
const Activities = lazy(() => import("../pages/UserCenter/Activities"));
const StarTrekbox = lazy(() => import("../pages/UserCenter/StarTrekbox"));
const Starcore = lazy(() => import("../pages/UserCenter/Starcore"));
const Staking = lazy(() => import("../pages/UserCenter/Staking"));
const Setting = lazy(() => import("../pages/UserCenter/Setting"));
const Achievements = lazy(() => import("../pages/Achievements"));
const SpaceShip = lazy(() => import("../pages/SpaceShip"));
const CrystalNFT = lazy(() => import("../pages/CrystalNFT"));
const FreemintPage = lazy(() => import("../pages/FreemintPage"));
const FreemintPage2 = lazy(() => import("../pages/FreemintPage2"));
const BattlepointPage = lazy(() => import("../pages/BattlepointPage"));
const OuterPage = lazy(() => import("@/pages/OuterPage"));
const StarfallPage = lazy(() => import("@/pages/StarfallPage"));
const SurvivorPage = lazy(() => import("@/pages/SurvivorPage"));
const EndlesswarPage = lazy(() => import("@/pages/EndlesswarPage"));
const InvitedInfoPage = lazy(
  () => import("../pages/UserCenter/inviteCode/invitedInfoPage")
);
const NftUtiliy = lazy(() => import("../pages/UserCenter/NFTUtiliy/index"));
const PageContainer = lazy(() => import("../pages/Wheelgame/PageContainer"));
const BindUsername = lazy(() => import("../pages/BindUsername"));
const Registercomfirm = lazy(() => import("../pages/RegisterComfirm"));
const ResetPassword = lazy(() => import("../pages/ResetPassword"));

function Router() {
  return (
    <Routes>
      <Route path="/" element={<LazyCom com={Home} />} />
      <Route path="/test" element={<LazyCom com={TestWagmi} />} />
      <Route path="/login" element={<LazyCom com={Login} />} />
      <Route path="/register" element={<LazyCom com={Register} />} />
      <Route path="/bindUsername" element={<LazyCom com={BindUsername} />} />
      <Route path="/achievements" element={<LazyCom com={Achievements} />} />
      <Route path="/spaceship" element={<LazyCom com={SpaceShip} />} />
      <Route path="/crystal" element={<LazyCom com={CrystalNFT} />} />
      <Route path="/sign2" element={<LazyCom com={FreemintPage} />} />
      <Route path="/sign" element={<LazyCom com={FreemintPage2} />} />
      <Route path="/battlepoint" element={<LazyCom com={BattlepointPage} />} />
      <Route path="/leaderboards/outer" element={<OuterPage />} />
      <Route path="/leaderboards/starfall" element={<StarfallPage />} />
      <Route path="/leaderboards" element={<SurvivorPage />} />
      <Route path="/leaderboards/endlesswar" element={<EndlesswarPage />} />
      <Route path="/wheel" element={<LazyCom com={PageContainer} />} />
      <Route path="/invited/:code" element={<LazyCom com={MiniInvite} />} />
      <Route path="/user" element={<LazyCom com={AccountLayout} />}>
        <Route
          index
          path="/user/wallet"
          element={<LazyCom com={Dashboard} />}
        />
        <Route
          path="/user/referralProgram"
          element={<LazyCom com={RefferralProgram} />}
        />
        <Route
          path="/user/claimTokens"
          element={<LazyCom com={ClaimTokens} />}
        />
        <Route path="/user/member" element={<LazyCom com={Members} />} />
        <Route
          path="/user/sign-in-reward"
          element={<LazyCom com={SignInReward} />}
        />
        <Route path="/user/activities" element={<LazyCom com={Activities} />} />
        <Route path="/user/starTrekBox" element={<LazyCom com={StarTrekbox} />} />
        <Route path="/user/starcore" element={<LazyCom com={Starcore} />} />
        <Route path="/user/staking" element={<LazyCom com={Staking} />} />
        <Route path="/user/setting" element={<LazyCom com={Setting} />} />
        <Route
          path="/user/invite-code"
          element={<LazyCom com={InviteCode} />}
        />
        <Route
          path="/user/eligibility/:code"
          element={<LazyCom com={InvitedInfoPage} />}
        />
        <Route
          path="/user/nftUtility/:type"
          element={<LazyCom com={NftUtiliy} />}
        />
      </Route>
      <Route path="/account" element={<LazyCom com={MainLayout} />}>
        <Route
          path="/account/emailComfirm"
          element={<LazyCom com={Registercomfirm} />}
        />
        <Route
          path="/account/emailConfirmSuccess"
          element={<LazyCom com={Registercomfirm} />}
        />
        <Route
          path="/account/resetPassword"
          element={<LazyCom com={ResetPassword} />}
        />
      </Route>
      <Route path="/market" element={<LazyCom com={MainLayout}/>}>
        <Route path="/market/invite/:code" element={<LazyCom com={PageInvite} />}></Route>
      </Route>
    </Routes>
  );
}

export default Router;
