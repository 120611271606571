import React from "react";
import styles from "./index.module.css";
import { motion } from "framer-motion";

export default function LoadingScreen() {
  return (
    <div
      style={{
        right: 0,
        bottom: 0,
        zIndex: 99999,
        width: "100%",
        height: "100%",
        position: "fixed",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#000"
      }}
    >
      <motion.div
        initial={{ rotateY: 0 }}
        animate={{ rotateY: 360 }}
        transition={{
          duration: 2,
          ease: "easeInOut",
          repeatDelay: 1,
          repeat: Infinity,
        }}
      >
        {/* https://assets.darkstar.center/frontend/images/CRYSTALFUN-logo.svg */}
        <img
          src="https://assets.darkstar.center/frontend/img/common/darkstar-logo.png"
          alt="logo"
          style={{
            width: 90,
            height: 90,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      </motion.div>
      <motion.div
        animate={{
          scale: [1.2, 1, 1, 1.2, 1.2],
          rotate: [270, 0, 0, 270, 270],
          opacity: [0.25, 1, 1, 1, 0.25],
          borderRadius: ["25%", "25%", "50%", "50%", "25%"],
        }}
        transition={{ ease: "linear", duration: 3.2, repeat: Infinity }}
        style={{
          width: 100,
          height: 100,
          borderRadius: "25%",
          position: "absolute",
          border: `solid 3px rgba(0,29,20)`,
          opacity: 0.24
        }}
      />

      <motion.div
        animate={{
          scale: [1, 1.2, 1.2, 1, 1],
          rotate: [0, 270, 270, 0, 0],
          opacity: [1, 0.25, 0.25, 0.25, 1],
          borderRadius: ["25%", "25%", "50%", "50%", "25%"],
        }}
        transition={{
          ease: "linear",
          duration: 3.2,
          repeat: Infinity,
        }}
        style={{
          width: 120,
          height: 120,
          borderRadius: "25%",
          position: "absolute",
          border: `solid 8px rgb(0,29,20)`,
          opacity: 0.24
        }}
      />
    </div>
  );
}
