import axios from "axios";
import { getHttpToken, setHttpToken } from "./httpToken";
import i18next from "i18next";
import { showToast } from "@/components/showToast";
import { COMMON_TOAST_TYPE } from "@/components/showToast/constance";
import { isPrd } from ".";

export const SUCCESS_CODE = 0;

export const API_URL = isPrd()
  ? "https://api.darkstar.center"
  : "https://api-testnet.darkstar.center";

const service = axios.create({
  baseURL: API_URL,
  timeout: 15000,
});

service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    const { code, result, message } = response?.data;
    const { showError = true, cacheToken } = response?.config as any;
    if (result?.code === SUCCESS_CODE) {
      if (cacheToken) {
        const { token } = result;
        if (token) {
          setHttpToken(token);
        }
      }
      return result;
    } else {
      if (!result?.hasOwnProperty("code")) {
        if (code === SUCCESS_CODE) {
          return result;
        }
      }
      const msg = message || result?.message || "Somthing run wrong";
      if (showError) {
        const i18Err = `api.${msg}`;
        const val = i18next.t(i18Err);
        if (val && val !== i18Err) {
          showToast(val, COMMON_TOAST_TYPE.WARNNING);
        } else {
          showToast(msg, COMMON_TOAST_TYPE.WARNNING);
        }
      }
      return Promise.reject(result);
    }
  },
  (error) => {
    const msg = "Somthing run wrong";
    if (error.config?.showError) {
      showToast(msg, COMMON_TOAST_TYPE.WARNNING);
    }
    return Promise.reject(error);
  }
);

export const httpGet = (url: string, param: any = null) => {
  let paramStr = "";
  if (param) {
    for (let k in param) {
      const val = param[k];
      paramStr += `${k}=${JSON.stringify(val)}&`;
    }
    paramStr = paramStr.slice(0, paramStr.length - 1);
  }
  const requestUrl = param ? `${url}?${paramStr}` : url;
  return service.get(requestUrl);
};

export const httpPost = (url: string, pParam = {}, options: any = null) => {
  const param = {
    // options: JSON.stringify(pParam),
    ...pParam,
  };
  let hasAuthorization = options?.cacheToken ? false : true;
  if (options?.hasOwnProperty("hasAuthorization")) {
    hasAuthorization = options?.hasAuthorization;
  }
  const postOption = { ...options };
  postOption.headers = postOption.headers || {};
  if (hasAuthorization) {
    const token = getHttpToken();
    postOption.headers["Authorization"] = `Bearer ${token}`;
  }

  return service.post(url, param, { ...postOption });
};
