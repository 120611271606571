import { useConnectModal } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";

/**
 * 用户是否调起链接钱包弹窗，并链接成功
 * @param abiName abi 文件的文件名
 * @returns
 */
export default function useGetConnectSuccess() {
  const { address, chainId } = useAccount();
  const { connectModalOpen } = useConnectModal();
  const isConnected = address && chainId; //&& connectModalOpen;
  return {
    isConnected,
    address: address,
  };
}
