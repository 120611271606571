import { useEffect, useState } from "react";
import "../index.css";
import InviteList from "../inviteList";
import PageHeader from "./PageHeader";
import { httpPost } from "../../../../utils/httpRequest";
import { useAccount } from "wagmi";
import { getChainNameById } from "../../../../utils";

function InviteCode() {
  const { chainId } = useAccount();

  // api getContributorInfo service data
  const [sInfo, setSInfo] = useState<any>();

  const getListInfo = async () => {
    const reqData = {
      // TODO:
      chain: {}, // getChainNameById(chainId),
    };
    const resp = await httpPost("/v1/invitecode/getInviteCodeInfo", reqData);
    setSInfo(resp);
  };

  useEffect(() => {
    getListInfo();
  }, []);

  return (
    <div className="invitation-code-content">
      <PageHeader point={sInfo?.holdPoints} />
      <InviteList inviteList={sInfo?.invateCodes} />
    </div>
  );
}

export default InviteCode;
