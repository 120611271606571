export function setHttpToken(token: string) {
  if (!!token) {
    localStorage.setItem("game_token", token);
  } else {
    localStorage.removeItem("game_token");
  }
}

export function getHttpToken() {
  return localStorage.getItem("game_token");
}
