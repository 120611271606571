import { chainIcons } from "../../../config/contract/chainIcons";
import styles from "./index.module.css";

function ChainBtn(props: any) {
  const { name, id, onClose, switchFn } = props;

  const getCurChainIcon = chainIcons[id];

  const onBtnClick = () => {
    switchFn?.(id);
    onClose?.();
  };

  return (
    <button className={styles["chain-btn"]} onClick={onBtnClick}>
      {
        <img
          style={{ width: "40px", height: "40px", borderRadius: "50%" }}
          src={getCurChainIcon}
        />
      }
      <label className={styles["chain-text"]}>{name}</label>
    </button>
  );
}

export default ChainBtn;
