import styles from "./index.module.css";

function CloseBtn(props: { onClose: () => void }) {
  const { onClose } = props;
  return (
    <div className={styles.modalCloseBtn} onClick={onClose}>
      <svg
        aria-hidden="true"
        fill="none"
        height="11.5"
        viewBox="0 0 11.5 11.5"
        width="11.5"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Close</title>
        <path
          d="M2.13388 0.366117C1.64573 -0.122039 0.854272 -0.122039 0.366117 0.366117C-0.122039 0.854272 -0.122039 1.64573 0.366117 2.13388L3.98223 5.75L0.366117 9.36612C-0.122039 9.85427 -0.122039 10.6457 0.366117 11.1339C0.854272 11.622 1.64573 11.622 2.13388 11.1339L5.75 7.51777L9.36612 11.1339C9.85427 11.622 10.6457 11.622 11.1339 11.1339C11.622 10.6457 11.622 9.85427 11.1339 9.36612L7.51777 5.75L11.1339 2.13388C11.622 1.64573 11.622 0.854272 11.1339 0.366117C10.6457 -0.122039 9.85427 -0.122039 9.36612 0.366117L5.75 3.98223L2.13388 0.366117Z"
          fill="currentColor"
        ></path>
      </svg>
    </div>
  );
}

export default CloseBtn;
