import { useCallback, useEffect, useState } from "react";
import { useAccount, useSwitchChain, useReconnect } from "wagmi";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { useDebounceFn } from "ahooks";
import { getDefaultChainId } from "../utils";

function useConnectThen(fn: (chainId?: any) => Promise<any>, chainId?: number) {
  const { status } = useAccount();
  const [clicked, setClicked] = useState<boolean>(false);
  const { openConnectModal } = useConnectModal();
  const { chains, switchChainAsync } = useSwitchChain();

  const { run: debClick } = useDebounceFn(
    () => {
      setClicked(true);
    },
    { wait: 500 }
  );

  const menoFn = () => {
    if (status !== "connected") {
      // 未连接钱包
      openConnectModal?.();
      debClick();
      return;
    }
    // 链接了钱包，点击按钮，翻转状态然后执行fn 函数
    if (!clicked) {
      debClick();
    }
  };

  async function connectedSuccessFn(clicked: boolean, status: string) {
    if (clicked && status === "connected") {
      try {
        setClicked(false);
        fn?.(chainId);
      } catch (err) {
        console.log(err);
        setClicked(false);
      }
    }
  }

  useEffect(() => {
    connectedSuccessFn(clicked, status);
  }, [clicked, status]);

  return menoFn;
}

export default useConnectThen;
