import { useTranslation } from "react-i18next";
import styles from "../index.module.css";

function ContentBlock(props: { title: string; content: string }) {
  const { title, content } = props;
  const { t } = useTranslation();
  return (
    <div className={styles.contentBlock}>
      <p className={styles.title}>{t(title)}</p>
      <p className={styles.blockContent}>{t(content)}</p>
    </div>
  );
}

export default ContentBlock;
