import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";

function MiniInvite() {
  const navigate = useNavigate();
  const { code } = useParams();
  useEffect(() => {
    navigate(`/user/eligibility/${code}`);
    // navigate(`/user/invited-info/${code}`);
    // navigate('/staking');
  }, []);
  return <div />;
}

export default MiniInvite;
