import ChainBtn from "./ChainBtn";
import styles from "./index.module.css";
import { getContractCfg } from "../../../config/contract";
import { useTranslation } from "react-i18next";

function ModalBody(props: any) {
  const { onClose, switchFn } = props;
  const { chains } = getContractCfg();
  const { t } = useTranslation();
  return (
    <div className={styles["chain-modal-body"]}>
      <div className={styles["switch-desc"]}>
        {/* This app doesn't support your current network. */}
        {t("common.swith_network_modal_desc_1")}
        <br />
        {/* Switch to an available option following to continue. */}
        {t("common.swith_network_modal_desc_2")}
      </div>
      <div className={styles["chain-content"]}>
        {chains.map((item: any) => {
          const { id, name } = item;
          return (
            <ChainBtn
              key={id}
              name={name}
              id={id}
              onClose={onClose}
              switchFn={switchFn}
            />
          );
        })}
      </div>
    </div>
  );
}

export default ModalBody;
