import MyButton from "./components/MyButton";
import { EnumWalletItem, mainContentCfg, walletDic } from "./constance";
import styles from "./index.module.css";
import ContentBlock from "./components/ContentBlock";
import { jumpToMetamaskApp, jumpToOKApp } from "@/utils";
import { useTranslation } from "react-i18next";

function MainView(props: { onGetWallet: () => void }) {
  const { onGetWallet } = props;
  const { t } = useTranslation();

  const onItemClick = (type: string) => {
    if (type === EnumWalletItem.metamask) {
      jumpToMetamaskApp();
    } else if (type === EnumWalletItem.okx) {
      jumpToOKApp();
    }
  };

  return (
    <>
      <div className={styles.wallets}>
        {Object.keys(walletDic)?.map((key, idx) => {
          const item = walletDic[key];
          return (
            <span
              className={styles.item}
              key={idx}
              onClick={() => {
                onItemClick(item.type);
              }}
            >
              <img className={styles.itemImg} src={item.img} alt="" />
              <span className={styles.itemDesc}>{item.desc}</span>
            </span>
          );
        })}
      </div>
      <div className={styles.content}>
        <ContentBlock {...mainContentCfg} />
        <div className={styles.contentBtm}>
          <MyButton
            label={t("common.get_a_wallet")} //"Get a Wallet"
            onClick={() => {
              onGetWallet?.();
            }}
          />
          <MyButton label={t("common.learn_more")} onClick={() => {}} />
        </div>
      </div>
    </>
  );
}

export default MainView;
