import { Modal } from "antd";
import CommonTitle from "../components/CommonTitle";
import { useState } from "react";
import { walletDic } from "../mobile/constance";
import WalletItem from "../mobile/components/WalletItem";
import styles from "./index.module.css";
import { useTranslation } from "react-i18next";

function NormalModal(props: { show: boolean; onClose: () => void }) {
  const { show, onClose } = props;
  const { t } = useTranslation();
  const onOk = () => {};
  const onCancel = () => {};
  const [isGetWallet, setIsGetWallet] = useState(false);

  return (
    <Modal
      open={show}
      centered
      onOk={onOk}
      width={368}
      onCancel={onCancel}
      closeIcon={null}
      footer={null}
    >
      <CommonTitle
        title={t("common.connectAWallet")}
        showBack={isGetWallet}
        onClose={onClose}
        onBack={() => {
          setIsGetWallet(false);
        }}
      />
      {Object.keys(walletDic).map((key: any, idx: number) => {
        const item = walletDic[key];
        return (
          <WalletItem
            key={idx}
            type={item.type}
            className={styles.walletItem}
          />
        );
      })}
    </Modal>
  );
}

export default NormalModal;
