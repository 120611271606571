import styles from "./index.module.css";
import { getIsMobi } from "@/utils";
import Mobilepopup from "./mobile";
import NormalModal from "./normal";

function NoWalletModal(props: { show: boolean; onClose: () => void }) {
  const { show, onClose } = props;

  const isMobile = getIsMobi();

  return (
    <>
      {isMobile ? (
        <Mobilepopup show={show} onClose={onClose} />
      ) : (
        <NormalModal show={show} onClose={onClose} />
      )}
    </>
  );
}

export default NoWalletModal;
