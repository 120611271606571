const addressConfig: any = {
  0x1: {
    OTActiveSign: "0x225aB522A21b06F322f3EB7466396287418dcf92",
    OTActiveSignLimit: "0x64DF9e435C1B07b3bD1Fd36862cD8180C3AA2B94",
    OTSpaceship: "0x13251cE31ac3330067C3acAf4Bb3339dfF765C76",
  },
  0xc4: {
    OTActiveSign: "0x225aB522A21b06F322f3EB7466396287418dcf92",
    OTActiveSignLimit: "0x64DF9e435C1B07b3bD1Fd36862cD8180C3AA2B94",
    OTSpaceship: "0x4BDEf63e6f1D5A855ce3E18D1Cbec6d7d04d0eD1",
  },
  0xc3: {
    OTActiveSign: "0x7F0f2E4dC9c490db329214B87b541283502c55dB",
    OTActiveSignLimit: "0x9d2Fb5C5730CB092afdf420d6E9B76A7c2fFD122",
    OTSpaceship: "0x4BDEf63e6f1D5A855ce3E18D1Cbec6d7d04d0eD1",
  },
  0xaa36a7: {
    OTActiveSign: "0x7F0f2E4dC9c490db329214B87b541283502c55dB",
    OTActiveSignLimit: "0x9d2Fb5C5730CB092afdf420d6E9B76A7c2fFD122",
    OTSpaceship: "0xA366A75132344cCA12f97F61986bef63b489db47",
  },
  0x463: {
    OTActiveSign: "0x7F0f2E4dC9c490db329214B87b541283502c55dB",
    OTActiveSignLimit: "0x9d2Fb5C5730CB092afdf420d6E9B76A7c2fFD122",
    OTSpaceship: "0xA366A75132344cCA12f97F61986bef63b489db47",
  },
  0x66eee: {
    OTActiveSign: "0x7F0f2E4dC9c490db329214B87b541283502c55dB",
    OTActiveSignLimit: "0x9d2Fb5C5730CB092afdf420d6E9B76A7c2fFD122",
    OTSpaceship: "0xA366A75132344cCA12f97F61986bef63b489db47",
  },
  0x1001: {
    OTActiveSign: "0x7F0f2E4dC9c490db329214B87b541283502c55dB",
    OTActiveSignLimit: "0x9d2Fb5C5730CB092afdf420d6E9B76A7c2fFD122",
    OTSpaceship: "0xA366A75132344cCA12f97F61986bef63b489db47",
  },
};

export default addressConfig;
