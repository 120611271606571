import { mainnet, xLayer, arbitrum } from "wagmi/chains";

const prdCfg = {
  0x1: {
    ...mainnet,
  },
  0xc4: {
    ...xLayer,
  },
  0xdf: {
    id: 0xdf,
    name: "b2",
    nativeCurrency: {
      decimals: 18,
      name: "b2",
      symbol: "b2",
    },
    rpcUrls: {
      default: { http: ["https://rpc.bsquared.network"] },
    },
    blockExplorers: {
      default: {
        name: "b2",
        url: "https://rpc.bsquared.network",
      },
    },
    contracts: {
      multicall3: {
        address: "",
        blockCreated: 0,
      },
    },
    testnet: true,
  },
  0xa4b1: {
    ...arbitrum,
  },
  0x2019: {
    id: 0x2019,
    name: "Kaia Mainnet",
    nativeCurrency: {
      decimals: 8217,
      name: "KAIA",
      symbol: "KAIA",
    },
    rpcUrls: {
      default: { http: ["https://public-en.node.kaia.io"] },
    },
    blockExplorers: {
      default: {
        name: "Kaia Mainnet",
        url: "https://kairos.io",
      },
    },
    testnet: false,
  },
};

export default prdCfg;
