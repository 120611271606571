import NiceModal from "@ebay/nice-modal-react";
import Content from "./content";

export const showJoinSocialModal = () => {
  const needShowModal = localStorage.getItem("showSocialModal");
  if (needShowModal !== "1") {
    localStorage.setItem("showSocialModal", "1");
    // NiceModal.show(Content);
  }
};
