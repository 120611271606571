import NiceModal from "@ebay/nice-modal-react";
import Content from "./content";
import { TypeSwitchNetwrodModal } from "./type";

export const showSwitchNetwordModal = (props: TypeSwitchNetwrodModal) => {
  NiceModal.show(Content, props);
};

export const hideSwitchNetwordModal = () => {
  NiceModal.hide(Content);
};
