import { useEffect, useState } from "react";
import {
  useAccount,
  useChainId,
  useConfig,
  useDisconnect,
  useSwitchChain,
} from "wagmi";
import { getContractCfg } from "./config/contract";
import { showSwitchNetwordModal } from "./components/MyModal/SwithNetworkModal";
import { useLocation, useNavigate } from "react-router-dom";
import { checkNoInstallWallet, getLoginInfo } from "./utils";
import useLoginByWallet from "./hooks/useLoginByWallet";
import useLoginOut from "./hooks/useLoginOut";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { showNoWalletModal } from "./components/NoWalletModal";
import NoWalletModal from "./components/NoWalletMobileModal";

// 不打开切链面板的页面
const noShowPageList = ["/login"];
// 隐藏安装钱包的 modal的页面
const hideInstallWalletModal: any = [];

function Wallet() {
  const { chainId, address } = useAccount();
  const { address: lastLoginAddress } = getLoginInfo() || {};
  const curChainId = useChainId();
  console.log("=====useAccount.chainId==========", chainId);
  const { switchChain } = useSwitchChain();
  const { disconnect } = useDisconnect();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const loginByWalletfn = useLoginByWallet();
  const disconnectFun = useLoginOut();
  const { openConnectModal } = useConnectModal();
  const [showNoWallet, setShowNoWallect] = useState(false);

  const needShowModal =
    noShowPageList.findIndex((item) => {
      return item.indexOf(pathname) > -1;
    }) < 0;

  const needShowInstallModal =
    hideInstallWalletModal.findIndex((item: any) => {
      return item.indexOf(pathname) > -1;
    }) < 0;

  function onNoWalletClose() {
    setShowNoWallect(false);
  }

  useEffect(() => {
    const { chains } = getContractCfg();
    if (chainId) {
      const isSupport =
        chains?.findIndex((item) => {
          return item.id === chainId;
        }) > -1;
      if (lastLoginAddress && lastLoginAddress !== address) {
        // 与上次登录的地址不一样，重新登录
        loginByWalletfn();
        return;
      }
      if (!isSupport && needShowModal) {
        // 打开切链面板;
        showSwitchNetwordModal({
          switchFn: (id) => {
            switchChain({ chainId: id });
          },
          disconnectFn: () => {
            disconnectFun?.();
          },
        });
      }
    } else {
      if (!checkNoInstallWallet()) {
        // no wallet
        // showNoWalletModal();
        setShowNoWallect(true);
      } else {
        // no connect
        if (needShowModal) {
          openConnectModal?.();
        }
      }
    }
  }, [chainId, address]);

  return (
    <div>
      <NoWalletModal show={showNoWallet} onClose={onNoWalletClose} />
    </div>
  );
}
export default Wallet;
