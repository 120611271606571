import classNames from "classnames";
import { walletDic } from "../constance";
import styles from "./index.module.css";

function WalletItem(props: { type: string; className?: any }) {
  const { type, className } = props;
  const { img, desc, url } = walletDic[type] || {};

  const onLink = () => {
    window.open(url, "_blank");
  };

  return (
    <div className={classNames(styles.walletItem, className)}>
      <img className={styles.icon} src={img} />
      <div className={styles.desc}> {desc}</div>
      <div className={styles.linkBtn} onClick={onLink}>
        Get
      </div>
    </div>
  );
}

export default WalletItem;
