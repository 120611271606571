import styles from "./index.module.css";
import DisconnectSvg from "./svg/DisconnectSvg";

function ModalBottom(props: any) {
  const { onClose, disconnectFn } = props;
  return (
    <div className={styles["chain-modal-footer"]}>
      <div className={styles["orline"]}>
        <span>or</span>
      </div>
      <div className={styles["chain-content"]}>
        <button
          className={styles["chain-btn"]}
          onClick={() => {
            disconnectFn?.();
            onClose?.();
          }}
        >
          <DisconnectSvg />
          <label className={styles["chain-text"]}>Disconnect</label>
        </button>
      </div>
    </div>
  );
}

export default ModalBottom;
