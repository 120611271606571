import BackBtn from "./BackBtn";
import CloseBtn from "./CloseBtn";
import styles from "./index.module.css";

function CommonTitle(props: {
  title: string;
  showBack: boolean;
  onClose: () => void;
  onBack: () => void;
}) {
  const { title, showBack, onClose, onBack } = props;
  return (
    <div className={styles.commonTitle}>
      {showBack ? <BackBtn onBack={onBack} /> : <div />}
      <p className={styles.title}>{title}</p>
      <CloseBtn onClose={onClose} />
    </div>
  );
}

export default CommonTitle;
