import styles from "../index.module.css";

function MyButton(props: { label: string; onClick: () => void }) {
  const { label, onClick } = props;
  return (
    <div className={styles.btn} onClick={onClick}>
      {label}
    </div>
  );
}

export default MyButton;
