import { Bounce } from "react-toastify";
import styles from "./index.module.css";

const SUCCESS_ICON =
  "https://assets.outer.gg/frontend/img/common/i-success.svg";
const WARNNING_ICON =
  "https://assets.outer.gg/frontend/img/common/i-warning.svg";

const SUCCESS = "1";
const WARNNING = "2";

export enum COMMON_TOAST_TYPE {
  SUCCESS,
  WARNNING,
}

export const dicCfg: any = {
  [COMMON_TOAST_TYPE.SUCCESS]: {
    title: "Success!",
    icon: SUCCESS_ICON,
    style: "success",
  },
  [COMMON_TOAST_TYPE.WARNNING]: {
    title: "Warning!",
    icon: WARNNING_ICON,
    style: "warning",
  },
};

export type ToastType = COMMON_TOAST_TYPE.SUCCESS | COMMON_TOAST_TYPE.WARNNING;

export const toastCfg: any = {
  position: "top-right",
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
  transition: Bounce,
};
