function DisconnectSvg() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M78.811 49.654C73.4734 71.0695 51.7832 84.1465 30.3283 78.8058C8.91278 73.4519 -4.14326 51.7615 1.19428 30.346C6.53182 8.93052 28.209 -4.14654 49.6115 1.19424C71.0794 6.46958 84.1354 28.2254 78.811 49.654Z"
        fill="#313232"
      ></path>
      <path
        d="M30.0009 41.4746L26.948 46.7622C24.8405 50.4126 26.0912 55.0803 29.7416 57.1878V57.1878C33.3919 59.2953 38.0596 58.0446 40.1671 54.3943L43.2199 49.1066"
        stroke="#B9B9B9"
        strokeWidth="4"
      ></path>
      <path
        d="M49.3262 38.5293L52.379 33.2417C54.4865 29.5913 53.2358 24.9236 49.5855 22.8161V22.8161C45.9351 20.7086 41.2674 21.9593 39.1599 25.6096L36.1071 30.8973"
        stroke="#B9B9B9"
        strokeWidth="4"
      ></path>
      <path
        d="M26.5715 32.1426L53.0097 47.4067"
        stroke="#B9B9B9"
        strokeWidth="4"
      ></path>
    </svg>
  );
}

export default DisconnectSvg;
